import { mapActions, mapGetters } from "vuex";

export default {
    name: "sales-Invoice",
    components: {},
    data() {
        return {
            isOpen: false,
            showDisplacement: "",
        };
    },
    props: {
        type: {
            type: String,
        },
        displacement: {
            type: Object,
            default: () => {},
        },
        showElement: {
            type: Boolean,
            default: true,
        },
        document: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters({}),
    },
    created() {},
    methods: {
        ...mapActions({}),
        openDisplacement(item) {
            this.$emit("open-displacement", item);
            this.isOpen = true;
        },
    },
};
